import React from "react";
import { Link } from "react-router-dom";

import "./Anchor.css";

function Anchor({ 
            title, // text of the link
             href, // url of the link
           imgSrc, // image url
           imgAlt, // alt text for the image
        className  // css classname for this Component
    }) {
    /*
        Inputs
            title : link name
            href : link target
            className : styles class name
            imgSrc : image url
            imgAlt : alt text for image
    */

    function renderContent() {
        if (imgSrc) {
            return <img src={imgSrc} className="img-section-fullwidth" alt={imgAlt} />;
        } else if (title) {
            return title;
        }
    }

    function renderLink() {
        if (href && href.includes("http")) {
            return (
                <a href={href} className={className}>{renderContent()}</a>
            )
        } else if (href === null) {
            return (
                <div className={className}>{renderContent()}</div>
            )
        } else {
            return (
                <Link to={"/" + href} className={className}>
                    {renderContent()}
                </Link>
            )
        }
    }

    return (
        <>
            {renderLink()}
        </>
    )
}

export default Anchor;