import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import Resume from "./pages/Resume";
import Services from "./pages/Services";
import ThankYou from "./pages/ThankYou";
import NoPage from "./pages/NoPage";
import Search from "./pages/Search";
import ScrollToTop from "./ScrollToTop";

import './App.css';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="projects" element={<Projects />} />
          <Route path="resume" element={<Resume />} />
          <Route path="services" element={<Services />} />
          <Route path="thankyou" element={<ThankYou />} />
          <Route path="Search" element={<Search />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;