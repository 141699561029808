import React, { useEffect } from 'react';
import Card from './components/Card/Card';
import CardContent from './components/Card/CardContent';
import ProjectContent from './components/Card/ProjectContent';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import TitleCard from "./components/TitleCard/TitleCard";
import TrustedClients from './components/TrustedClients/TrustedClients';
import OtherClients from './components/OtherClients/OtherClients';
import Repositories from './components/Repositories/Repositories';

function Projects() {
    useEffect(() => {
        document.title = "Projects";
        document.body.id = "projects";
    }, []);

    const { projects } = CardContent;

    function renderPageCard() {
        const { resume } = CardContent;
        const { id, title, tagline, desc, href } = resume;

        return (
            <Card 
                num={id} 
                title={title} 
                tagline={tagline} 
                desc={desc} 
                href={href} 
                orientation="right"
            />
        )
    }

    function renderProjectCards() {
        const projects = ProjectContent;
        var orientation = "right";

        return projects.map((project, key) => {
            orientation = (orientation === "right") ? "left" : "right";
            return (
                <Card 
                    num={project.id} 
                    title={project.title} 
                    tagline={project.tagline} 
                    desc={project.desc} 
                    href={project.href} 
                    imgSrc={project.imgSrc}
                    orientation={orientation}
                    noContainer={true}
                    key={key}
                />
            )
        });
    }

    return (
        <>
            <div className="banner projects"></div>
            <div className="container">
                <TitleCard 
                    header={projects.title}
                    tagline={projects.tagline}
                    content={projects.desc} 
                />
                {renderProjectCards()}
                <OtherClients />
                <hr className="thin" />
                <Repositories />
            </div>
            <TrustedClients />
            {renderPageCard()}
            <Contact />
            <Footer />
        </>
    );
}

export default Projects;