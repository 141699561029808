import React, { useEffect } from 'react';
import Card from './components/Card/Card';
import CardContent from './components/Card/CardContent';
import TitleCard from "./components/TitleCard/TitleCard";
import TrustedClients from './components/TrustedClients/TrustedClients';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import JobContent from './components/Card/JobContent';

function Resume() {
    useEffect(() => {
        document.title = "Resume";
        document.body.id = "resume";
    }, []);

    const { resume } = CardContent;
    
    function renderPageCard() {
        const { projects } = CardContent;
        const { id, title, tagline, desc, href } = projects;

        return (
            <Card 
                num={id} 
                title={title} 
                tagline={tagline} 
                desc={desc} 
                href={href} 
                orientation="right"
            />
        )
    }

    function renderJobCards() {
        const jobs = JobContent;
        var orientation = "right";

        return jobs.map((job, key) => {
            orientation = (orientation === "right") ? "left" : "right";
            return (
                <Card 
                    title={job.title} 
                    tagline={job.tagline} 
                    desc={job.desc} 
                    href={job.href}
                    duration={job.duration} 
                    tags={job.tags}
                    imgSrc={job.imgSrc}
                    orientation={orientation}
                    noContainer={true}
                    key={key}
                />
            )
        });
    }

    return (
        <>
            <div className="banner resume"></div>
            <div className="container">
                <TitleCard 
                    header={resume.title}
                    tagline={resume.tagline}
                    content={resume.desc} 
                />
                {renderJobCards()}
            </div>
            <TrustedClients />
            {renderPageCard()}
            <Contact />
            <Footer />
        </>
    );
}

export default Resume;