import React from "react";
import NavbarLinks from "../Navbar/NavbarLinks";
import Socials from "./Socials";

import "./Footer.css";

function Footer() {
    return (
        <footer>
            <div className="container">
                <div className="grid-three">
                    <div>
                        <img src={require("../common/logo.png")} width="50px" alt="KPZA" />
                        <p className="brand haas-medium">ZOTY</p>
                        <p className="text">Think it, Code it.</p>
                        {Socials.map(({href, icon}, key) => {
                            return (
                                <a className="text icon" href={href} target="_blank" rel="noreferrer" key={key}>
                                    <i className={icon}></i>
                                </a>
                            );
                        })}
                    </div>
                    <div>
                        <p>Quick Links</p>
                        {NavbarLinks.map(({source, title}, key) => {
                            if (key > 0) {
                                return (
                                    <a className="text link" href={source} key={key}>
                                        {title}
                                    </a>
                                );
                            } else {
                                return "";
                            }
                        })}
                    </div>
                    <div>
                        <p>Language</p>
                        <a className="text link active" href="/">EN</a>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;