import React, { useState } from "react";

import "./PricingForm.css";

function PricingForm() {
    const [formData, setFormData] = useState({
        FNAME: "",
        EMAIL: "",
        MMERGE2: ""
    });

    function handleChange(event) {
        const { name, value } = event.target;
    
        setFormData((previous) => {
          return {
            ...previous, // get previous array
            [name]: value // set value of changed value
          }
        });
      }

    return (
        <div id="contactform">
            <div id="contact-form" style={{display: "none"}}>
                <div className="container">
                    <p className="haas-light text-center" id="step-text">NEXT STEP</p>
                    <h1 id="content-header" className="section-link">
                        Send Me The Details
                    </h1>
                    <p className="text-center">I'll keep in touch to further refine your project scope and get your project on the move!</p>
                    <div id="mc_embed_signup">
                        <form 
                            action="https://zoty.us4.list-manage.com/subscribe/post?u=2dc29d96b21031752bbb29526&amp;id=bf2d66b68a" 
                            method="post" 
                            id="mc-embedded-subscribe-form" 
                            name="mc-embedded-subscribe-form" 
                            className="validate" 
                            target="_blank" 
                            noValidate 
                            autoComplete="off">
                            <div id="mc_embed_signup_scroll">
                                <div className="grid">
                                    <div>
                                        <input 
                                            type="text" 
                                            placeholder="Name" 
                                            name="FNAME" 
                                            className="form-field required" 
                                            id="mce-FNAME" 
                                            onChange={handleChange}
                                            value={formData.FNAME} />
                                        <input 
                                            type="email" 
                                            placeholder="Email" 
                                            name="EMAIL" 
                                            className="form-field required email" 
                                            id="mce-EMAIL" 
                                            onChange={handleChange}
                                            value={formData.EMAIL} />
                                    </div>
                                    <div>
                                        <textarea 
                                            maxLength="500" 
                                            placeholder="Project details" 
                                            name="MMERGE2" 
                                            className="form-field" 
                                            id="mce-MMERGE2"
                                            onChange={handleChange}
                                            value={formData.MMERGE2} >
                                        </textarea>
                                    </div>
                                </div>
                                <div id="mce-responses" className="clear">
                                    <div className="response" id="mce-error-response" style={{display: "none"}}></div>
                                    <div className="response" id="mce-success-response" style={{display: "none"}}></div>
                                </div>

                                <br />
                                
                                <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true">
                                    <input 
                                        type="text" 
                                        name="b_2dc29d96b21031752bbb29526_bf2d66b68a" 
                                        tabIndex="-1" 
                                        defaultValue="" />
                                </div>
                                <button type="submit" name="subscribe" id="mc-embedded-subscribe" className="btn btn-gold">Send It</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PricingForm;