import React from "react";

import "./TitleCard.css";

function TitleCard({
         header, // header text
        tagline, // tagline text
        content // content text
    }) {
    return (
        <>
            <div className="page-title-card">
                <p className="haas-light text-center">{tagline}</p>
                <h1 id="content-header" className="section-link">
                    {header}
                </h1>
            </div>
            <p className="content">
                {content}
            </p>
        </>
    );
}

export default TitleCard;