import React from "react";
import Socials from "../Footer/Socials";

import "./SmallFooter.css";

function SmallFooter() {
    return (
        <footer className="smallFooter">
            <div className="container">
                <div className="grid-three">
                    <div>
                        <p className="brand haas-medium">ZOTY</p>
                    </div>
                    <div>
                        <a href="/"><img src={require("../common/logo.png")} width="30px" alt="KPZA" /> </a>
                    </div>
                    <div>
                        <p className="text">
                        {Socials.map(({href, icon}, key) => {
                            return (
                                <a className="text icon" href={href} target="_blank" rel="noreferrer" key={key}>
                                    <i className={icon}></i>
                                </a>
                            );
                        })}
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default SmallFooter;