import React from "react";
import Clients from "./Clients";

import "./TrustedClients.css";

function TrustedClients() {
    return (
        <section id="trusted-clients">
            <div className="container">
                <p className="haas-medium text-center">BIG NAMES WHO TRUST ME</p>
                <div className="logo-grid">
                {
                    Clients.map(({name, imgSrc, href}, key) => {
                        const img = require("./" + imgSrc);
                        return (
                            <div key={key}>
                                <a href={href} target="_blank" rel="noreferrer">
                                    <img src={img} alt={name} className={name} />
                                </a>
                            </div>
                        );
                    })  
                }
                </div>
            </div>
        </section>
    );
}

export default TrustedClients;