import React, { useEffect } from 'react';
import Card from './components/Card/Card';
import CardContent from './components/Card/CardContent';
import TitleCard from "./components/TitleCard/TitleCard";
import TrustedClients from './components/TrustedClients/TrustedClients';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import PricingTable from './components/PricingTable/PricingTable';

function Services() {
    useEffect(() => {
        document.title = "Services";
        document.body.id = "services";
    }, []);

    const { services } = CardContent;
    const { projects, resume } = CardContent;
    const { id: projectsId, title: projectsTitle, tagline: projectsTagline, desc: projectsDesc, href: projectsHref } = projects;
    const { id: resumeId, title: resumeTitle, tagline: resumeTagline, desc: resumeDesc, href: resumeHref } = resume;
    
    return (
        <>
            <div className="banner services"></div>
            <div className="container">
                <TitleCard 
                    header={services.title}
                    tagline={services.tagline}
                    content={services.desc} 
                />                
            </div>
            <PricingTable />
            <TrustedClients />
            <Card 
                num={projectsId} 
                title={projectsTitle} 
                tagline={projectsTagline} 
                desc={projectsDesc} 
                href={projectsHref} 
                orientation="left"
            />
            <Card 
                num={resumeId} 
                title={resumeTitle} 
                tagline={resumeTagline} 
                desc={resumeDesc} 
                href={resumeHref} 
                orientation="right"
            />
            <Contact />
            <Footer />
        </>
    );
}

export default Services;