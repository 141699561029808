const NavbarLinks = [
    {
        title: "ZOTY",
        source: ""
    },
    {
        title: "Projects",
        source: "projects"
    },
    {
        title: "Resume",
        source: "resume"
    },
    {
        title: "Services",
        source: "services"
    }
];

export default NavbarLinks;