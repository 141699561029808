const Socials = [
    {
        href: "https://github.com/knackepizza/",
        icon: "fab fa-git-alt fa-2x"
    },
    {
        href: "https://www.linkedin.com/in/nmilesn19/",
        icon: "fab fa-linkedin fa-2x"
    }
]

export default Socials;