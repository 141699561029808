const JobContent = [
    {
        title: "Upwork",
        tagline: "FREELANCE DEVELOPER",
        desc: "Here I worked on numerous projects, each using different stacks. Some were to fix a small issue on a website but others were to implement new features, make large changes, make new sites from scratch, or structure a client's site better.",
        duration: "March 2022 — Present",
        tags: ["React", "Shopify Liquid", "AWS", "Linux", "Firebase", "Node.js", "REST APIs", "PHP", "UX/UI", "Git"],
        imgSrc: "upwork.png",
        href: null
    },
    {
        title: "Gretrix",
        tagline: "LEAD TECHNICAL DEVELOPER",
        desc: "At Gretrix I worked on many different clients with varying project scopes. Lots of WordPress and Shopify Development, often centered around SEO and AMP Optimization. Custom Code required and provided in just about every situation.",
        duration: "Jul 2019 — March 2022",
        tags: ["WordPress", "PHP", "Shopify Liquid", "AWS", "CodeIgniter", "CentOS", "UX/UI", "WP Themes", "WP Apps", "Shopify Themes", "Shopify Apps", "SEO APIs", "Git", "SOAP", "XML", "Tag Manager"],
        imgSrc: "gretrix.png",
        href: "https://gretrix.com"
    },
    {
        title: "Seek Capital",
        tagline: "FULL-STACK WEB DEVELOPER",
        desc: "I worked on the Analytics and Automation Team as a Developer to implement web technologies, and modify Angular and PHP sites to give their landing pages and funnels better conversion rates. Utilized Git and AWS as well.",
        duration: "Aug 2019 — Nov 2020",
        tags: ["WordPress", "PHP", "AWS", "Linux & Ubuntu", "JavaScript", "UX/UI", "Git", "SSH", "Custom Themes & Apps", "Angular", "SEO"],
        imgSrc: "seekcapital.png",
        href: "https://seekcapital.com"
    },
    {
        title: "Kennesaw State University",
        tagline: "BACHELOR IN COMPUTER SCIENCE",
        duration: "Aug 2019 — May 2023",
        tags: [],
        imgSrc: "ksu.png",
        href: null
    }
];

export default JobContent;