import React, { useEffect } from 'react';
import Jumbotron from './components/Jumbotron/Jumbotron';
import Card from "./components/Card/Card";
import CardContent from './components/Card/CardContent';
import TrustedClients from "./components/TrustedClients/TrustedClients";
import Contact from "./components/Contact/Contact";
import Footer from './components/Footer/Footer';

function Home() {
    useEffect(() => {
        document.title = "Zoty";
        document.body.id = "home";
    }, []);

    const { projects, resume } = CardContent;
    const { id: projectsId, title: projectsTitle, tagline: projectsTagline, desc: projectsDesc, href: projectsHref } = projects;
    const { id: resumeId, title: resumeTitle, tagline: resumeTagline, desc: resumeDesc, href: resumeHref } = resume;
    
    return (
        <>
            <Jumbotron />
            <Card 
                num={projectsId} 
                title={projectsTitle} 
                tagline={projectsTagline} 
                desc={projectsDesc} 
                href={projectsHref} 
                orientation="left"
            />
            <TrustedClients />
            <Card 
                num={resumeId} 
                title={resumeTitle} 
                tagline={resumeTagline} 
                desc={resumeDesc} 
                href={resumeHref} 
                orientation="right"
            />
            <Contact />
            <Footer />
        </>
    );
}

export default Home;