import React from "react";
import { Link, NavLink } from "react-router-dom";

function NavbarLink({
         title, // navbarlink text 
          href, // url of the target
        number // this id of the link
    }) {
    var linkClass = "";
    var spanClass = "";

    if (number === 0) {
        linkClass = "brand";
        spanClass = "haas-bold";
    }

    // Closes the navbar after selecting an option
    function mobileClick() {
        document.getElementById("myTopnav").className = "topnav";
        // window.scrollTo(0, 0);
    }

    if (title === "ZOTY") {
        return (
            <Link to={"/" + href} className={linkClass} onClick={mobileClick}>
                <span className={spanClass}>
                    {title}
                </span>
            </Link>
        )
    }
    return (
        <NavLink to={"/" + href} activeclassname="active" className={linkClass} onClick={mobileClick}>
            <span className={spanClass}>
                {title}
            </span>
        </NavLink>
    );
}

export default NavbarLink;