import React from "react";
import ClientData from "./ClientData";

import "./OtherClients.css";

function OtherClients() {
    return (
        <>
            <p className="content text-center">
                Other clients I've worked with
            </p>
            <p className="haas-light text-center tags-list">
                {
                    ClientData.map(({name, href}, key) => {
                        if (href && href === null) {
                            return <button key={key}><span className="tag">{name}</span></button>
                        } else {
                            return <a href={href} target="_blank" rel="noreferrer" key={key}><span className="tag">{name}</span></a>
                        }
                    })
                }
            </p>
        </>
    )
}

export default OtherClients;