const RepositoryData = [
    {
        name: "Zoty",
        language: "React",
        desc: "This website was initially built with php but I rebuilt it to be modular with React.js!",
        href: "/",
    },
    {
        name: "Custom Popup",
        language: "Shopify",
        desc: "This Shopify App will let you create popups on your site easily with varying functions.",
        href: null
    },
    {
        name: "Vitasync",
        language: "Swift",
        desc: "A health app for iOS to sync your vital information to a doctor's office or loved ones.",
        href: null
    },
    {
        name: "Cryptography",
        language: "Python",
        desc: "A collection of all of my assignments in Cryptography at Kennesaw State University.",
        href: null
    },
    {
        name: "UNRAVEL",
        language: "PHP",
        desc: "My weird social media service Unravel. Made to help people find things in their areas. Repo is available upon request for interviews.",
        href: null
    },
    {
        name: "E*TRADE PHP API",
        language: "PHP",
        desc: "I made E*TRADE's API usable with php, since they don't support this on their own terms. Now, users can easily perform actions on their E*TRADE accounts with php.",
        href: null
    },
    {
        name: "Webull PHP API",
        language: "PHP",
        desc: "This is Webull's API usable with php. They technically don't support an API, but they have all of these urls lying about and it's possible to authenticate to.",
        href: null
    },
    {
        name: "Robinhood PHP API",
        language: "PHP",
        desc: "Here's Robinhood's API usable with php. Since the only way to use Robinhood API is through Python, there's not much great web support. I made it possible!",
        href: null
    }
];

export default RepositoryData;