import React from "react";

import "./Contact.css";

function Contact() {
    return (
        <section id="contact">
            <div className="container text-center">
                <p className="haas-medium">CONTACT ME</p>
                <p className="email">
                    <a href="mailto:nick@zoty.us" target="_blank" rel="noreferrer">
                        nick@zoty.us
                    </a>
                </p>
                <p className="haas-light">
                    If you want to discuss a cool project, or just want to drop a comment, do it here!
                </p>
            </div>
        </section>
    );
}

export default Contact;