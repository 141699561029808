/* 
    CardContent
        id : index
        title : the title of the card
        tagline : short piece of text above title
        desc : the description of the card
        imgSrc : source of image 
        href : what this card should link to
*/

const ProjectContent = [
    {
        id: 1,
        title: "Jon N. Film",
        tagline: "PORTFOLIO WEBSITE",
        desc: "Jon is a filmmaker who needed a website to use as a portfolio. The website is made from scratch with php to create dynamic components and pages. Content modifications are streamlined through a page to edit videos, text, links, and collections.",
        imgSrc: "jon.png",
        href: "https://jonnfilm.com/"
    },
    {
        id: 2,
        title: "The Profitron",
        tagline: "CRYPTO TRADING BOT",
        desc: "Profitron is a crypto trading bot backed with AI. It works with Robinhood and Webull, and has a component to do normal stocks in E*TRADE. Using ColdFusion and php backends, this program runs all the time and works off calls between the play notifier and the trade executor.",
        imgSrc: "profitron.png",
        href: null
    },
    {   
        id: 3,
        title: "Unravel",
        tagline: "SOCIAL MEDIA WEB-APP",
        desc: "Unravel makes it easy for you to find things to do in your area. Whether you're from a city, or you're just stopping in for vacation or work, use this app to get to know the city and the cool people in it. Post a group or join someone else's to get out there and have fun today.",
        imgSrc: "unravel.png",
        href: "https://getunravel.zoty.us"
    },
    {
        id: 4,
        title: "Rubberneckers Online",
        tagline: "MULTIPLAYER ROAD TRIP GAME",
        desc: "Rubberneckers Online is the e-version of the popular road-trip card game Rubberneckers. Now, you can play using a phone without the hassle of dealing cards, getting stuck with bad cards, or having to count up everyone's points at the game and pull out your phone's calculator like you're some kind of mongrel who doesn't know math (kidding). Anyways, I didn't want to count up points so I made the game do it for me.",
        imgSrc: "rubberneckers.png",
        href: "https://php.zoty.us/rubberneckers/"
    }
];

export default ProjectContent;