import React from "react";
import PricingData from "./PricingData";
import PricingForm from "./PricingForm";
import "./PricingTable.css";

function PricingTable() {

    function renderCards() {
        return PricingData.map(({primary, icon, title, desc, points}, key) => {
            const primaryStyles = (primary) ? " primary" : "";
            const buttonStyles = (primary) ? "btn-primary" : "btn-secondary";

            return (
                <div className={"pricing-option" + primaryStyles} key={key}>
                    <i className={icon + " fa-2x"}></i>
                    <p className="title content">{title}</p>
                    <p>{desc}</p>
                    <ul className="text-muted">
                        {points.map((point, key) => {
                            return (<li key={key}>{point}</li>)
                        })}
                    </ul>
                    <a href="#contactform" className={"btn " + buttonStyles} onClick={(e) => showForm(title.toUpperCase(), e)}>Select</a>
                </div>
            )
        })
    }

    function showForm(input) {
        var form = document.getElementById('contact-form');
        var stepText = document.getElementById('step-text');
        
        if (form.style.display === 'none') {
            form.style.display = 'block';
        }
        
        stepText.innerText = input + " - NEXT STEP";
    }

    return (
        <>
            <div className="container">
                <div className="grid pricing text-center">
                    {renderCards()}
                </div>
            </div>
            <PricingForm />
        </>
    );
}

export default PricingTable;