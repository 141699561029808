const PricingData = [
    {
        title: "Kickstarter",
        icon: "fal fa-star-shooting",
        desc: "The common website for most needs. You can sell products, advertise content, and give yourself a good online-presence.",
        points: ["WordPress or Shopify", "eCommerce", "Normal Features"],
        primary: true
    },
    {
        title: "Sandbox",
        icon: "fal fa-island-tropical",
        desc: "If you need something specific like an API to work on your site, or a new feature to be implemented, I can help you.",
        points: ["Custom Requests", "Added On Existing Sites", "Advanced Features"],
        primary: false
    }
];

export default PricingData;