import React from "react";

import "./Navbar.css";

import NavbarLink from "./NavbarLink";
import NavbarLinks from "./NavbarLinks";

/* Toggle between adding and removing the "responsive" class to topnav when the user clicks on the icon */
function navbarToggle(event) {
    event.preventDefault();

    var x = document.getElementById("myTopnav");
    if (x.className === "topnav") {
        x.className += " responsive";
    } else {
        x.className = "topnav";
    }
}

function Navbar() {
    return (
        <div className="topnav" id="myTopnav">
            {
                NavbarLinks.map(({title, source}, id) => {
                    return (
                        <NavbarLink title={title} href={source} key={id} number={id} />
                    );
                })
            }
            <button className="icon" onClick={navbarToggle}>
                <i className="fa fa-bars fa-2x"></i>
            </button>
        </div>
    );
}

export default Navbar;