import React from "react";
import RepositoryData from "./RepositoryData";

import "./Repositories.css";

function Repositories() {
    return (
        <>
            <p className="content text-center">
                GitHub Repositories
            </p>
            <div className="grid repos">
                {RepositoryData.map(({href, name, language, desc}, key) => {
                    const icon = (href) ? "fa-arrow-circle-right" : "fa-lock";

                    const content = (
                        <>
                            <p className="content">{name} <i className={"fal " + icon + " fa-sm text-muted onhover"}></i></p>
                            <p className="haas-light"><i className="fas fa-circle fa-xs languageCircle"></i> {language}</p>
                            <p className="text-muted">
                                {desc}
                            </p>
                        </>
                    );

                    if (href) {
                        return (
                            <a href={href} className={"repo " + language.toLowerCase()} target="_blank" rel="noreferrer" key={key}>
                                {content}
                            </a>
                        )
                    } else {
                        return (
                            <button className={"repo " + language.toLowerCase()} key={key}>
                                {content}
                            </button>
                        )
                    }
                })}
            </div>
        </>
    )
}

export default Repositories;