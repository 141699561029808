import React from "react";
import Anchor from "../common/Anchor";

import "./Jumbotron.css";

function Jumbotron() {
    return (
        <div className="marble-bg">
            <div className="main container">
                <div className="main-section">
                    <p className="haas-light">NICK NETTLETON</p>
                    <h1 className="main-title">
                        Atlanta based developer who can solve your problems with code.
                    </h1>
                    <Anchor title="Work With Me" href="services" className="link" />
                    <Anchor title="Learn More" href="resume" className="link secondary" />
                </div>
            </div>
        </div>
    )
}

export default Jumbotron;