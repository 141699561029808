const Clients = [
    {
        name: "Seek Capital",
        imgSrc: "seek.png",
        href: "https://www.seekcapital.com/"
    },
    {
        name: "FunBoy",
        imgSrc: "funboy.png",
        href: "https://www.funboy.com/"
    },
    {
        name: "Twillory",
        imgSrc: "twillory.png",
        href: "https://www.twillory.com/"
    },
    {
        name: "Imperia Caviar",
        imgSrc: "imperia.png",
        href: "https://imperiacaviar.com/"
    }
];

export default Clients;