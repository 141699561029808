import React, { useEffect } from 'react';
import SmallFooter from './components/SmallFooter/SmallFooter';

function Search() {
    useEffect(() => {
        document.title = "Search";
        document.body.id = "search";
    }, []);

    function preventDef(e) {
        e.preventDefault();
        e.stopPropagation();
        let input = document.getElementById('search-query').value;
        console.log('https://www.google.com.pk/search?btnG=1&pws=0&q='+encodeURIComponent(input));
        window.location.replace('https://www.google.com/search?btnG=1&pws=0&q='+encodeURIComponent(input));
    }

    return (
        <>
            <div className="banner search">
                <div className="container">
                    <form id="search-form" onSubmit={e => preventDef(e)}>
                        <input type="text" id="search-query" placeholder="" />
                    </form>
                </div>
            </div>
            <SmallFooter />
        </>
    );
}

export default Search;