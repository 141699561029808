import React from "react";
import Anchor from "../common/Anchor";

import "./Card.css";

function Card({ 
            num, // index of rendered card
         imgSrc, // url of image, if not automatic
    noContainer, // removes container padding
    orientation, // left or right, shows which side the image will be on
           tags, // small tag items to be placed under title
        tagline, // text to be rendered above title
          title, // the main headline
           href, // link the card should go to
       duration, // for jobs, duration of job text
           desc  // the long description of the card
    }) {

    var img = "";
    if (imgSrc) {
        img = require("./" + imgSrc);
    } else {
        img = require("./" + num + ".png");
    }

    var styles = "card";
    if (!noContainer) {
        styles += " container";
    }
    
    function renderOrientation() {
        const pClass = (orientation === "left") ? "haas-light" : "haas-light text-grid-left";

        function swapOrientation() {
            if (orientation === "right") {
                return (
                    <>
                        {content}
                        {cardImage}
                    </>
                )
            } else if (orientation === "left") {
                return (
                    <>
                        {cardImage}
                        {content}
                    </>
                )
            }
        }

        function renderTags() {
            const allTags = tags.map((tag, key) => {
                return (<span className="tag" key={key}>{tag}</span>);
            });

            return (
                <p className={pClass}>
                    {allTags}
                </p>
            );
        }
        
        const content = (
            <div>
                <p className="haas-light">{tagline}</p>
                <Anchor title={title} href={href} className="section-link" />
                <hr className="small" />
                {duration && <p className="haas-light">{duration}</p>}
                {tags && renderTags()}
                <p className={pClass}>
                    {desc}
                </p>
            </div>
        );

        const cardImage = (
            <div className="card-image">
                <Anchor href={href} imgSrc={img} imgAlt={title} />
            </div>
        );

        return (
            <section className={styles}>
                <div className="grid">
                    {swapOrientation()}
                </div>
            </section>
        );
    }

    return (
        <>
            {renderOrientation()}
        </>
    )
}

export default Card;