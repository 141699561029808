/* 
    CardContent
        id : index
        title : the title of the card
        tagline : short piece of text above title
        desc : the description of the card
        href : what this card should link to
*/

const CardContent = {
    projects: {
        id: 1,
        title: "My Projects",
        tagline: "CODING UP A STORM",
        desc: "Things I've worked on that I'm proud to put my name on. I've been able to work on lots of random projects in stacks I never would've used.",
        href: "projects"
    },
    resume: {   
        id: 2,
        title: "My Resume",
        tagline: "NOT A DUSTY TRAIL",
        desc: "The places I've worked at, and some of the things I've worked on with them. Always trying to make this list longer!",
        href: "resume"
    },
    services: {
        id: 3,
        title: "My Services",
        tagline: "HAPPY TO HELP YOU",
        desc: "I do all sorts of things in the realm of the web. Even if yours is off the web, let me know what you need and I'll see what I can do.",
        href: "services"
    },
    thankyou: {
        id: 4,
        title: "Thank You",
        tagline: "PLEASE WAIT UP TO 48 HOURS",
        desc: "I'll review your details and contact you back within the next few days. I look forward to talking to you!",
        href: "thankyou"
    },
    nopage: {
        id: 5,
        title: "Not Found",
        tagline: "404 ERROR",
        desc: "The page you're trying to view doesn't exist on this site. The page may have been moved to a new permalink, or is part of a campaign or promotion which has ended. Return to a page in the navigation bar or contact me about any questions."
    },
    search: {
        id: 6,
        title: "Search",
        tagline: "Look it up",
        desc: ""
    }
};

export default CardContent;