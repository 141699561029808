import React, { useEffect } from 'react';
import CardContent from './components/Card/CardContent';
import TitleCard from './components/TitleCard/TitleCard';
import Footer from './components/Footer/Footer';

function NoPage() {
    useEffect(() => {
        document.title = "404";
        document.body.id = "error";
    }, []);

    const { nopage } = CardContent;
    
    return (
        <>
            <div className="banner nopage"></div>
            <div className="container">
                <TitleCard 
                    header={nopage.title}
                    tagline={nopage.tagline}
                    content={nopage.desc} 
                />
                <div className="bigspacer" />
            </div>
            <Footer />
        </>
    );
}

export default NoPage;