import React, { useEffect } from 'react';
import CardContent from './components/Card/CardContent';
import TitleCard from './components/TitleCard/TitleCard';
import Footer from './components/Footer/Footer';

function Home() {
    useEffect(() => {
        document.title = "Thank You";
        document.body.id = "thankyou";
    }, []);

    const { thankyou } = CardContent;
    
    return (
        <>
            <div className="banner thankyou"></div>
            <div className="container">
                <TitleCard 
                    header={thankyou.title}
                    tagline={thankyou.tagline}
                    content={thankyou.desc} 
                />
                <div className="bigspacer" />
            </div>
            <Footer />
        </>
    );
}

export default Home;