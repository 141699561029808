const ClientData = [
    {
        name: "Jon N. Film",
        href: "https://jonnfilm.com/"
    },
    {
        name: "SWSS",
        href: "https://www.swss.biz/"
    },
    {
        name: "Synergy",
        href: null
    },
    {
        name: "Seek Capital",
        href: "https://www.seekcapital.com/"
    },
    {
        name: "Imperia Caviar",
        href: "https://imperiacaviar.com/"
    },
    {
        name: "The Caviar Club",
        href: "https://thecaviarclub.com/"
    },
    {
        name: "Funboy",
        href: "https://www.funboy.com/"
    },
    {
        name: "Twillory",
        href: "https://www.twillory.com/"
    },
    {
        name: "Credit Nerd",
        href: "https://www.credit-nerd.com"
    },
    {
        name: "BM5L",
        href: "https://www.breakmy5thlawfitness.com/"
    },
    {
        name: "Floravere",
        href: null
    },
    {
        name: "Ikonick",
        href: "https://www.ikonick.com/"
    },
    {
        name: "Terminals USA",
        href: "https://www.terminalsusa.com/"
    },
    {
        name: "Industry Electric",
        href: "https://www.industryelectric.net/"
    },
    {
        name: "BeautyBio",
        href: "https://www.beautybio.com/"
    },
    {
        name: "Teach.org",
        href: "https://www.teach.org/"
    }
]

export default ClientData;